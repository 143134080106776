'use client'

import { sentryCaptureException } from "@/lib/sentry";
import { useEffect } from "react";

 
export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {

  useEffect(() => {
    sentryCaptureException(error);
  }, [error]);

  
  return (
    <html>
      <body>
        <h2>Something went wrong!</h2>
        <button onClick={() => reset()}>Try again</button>
      </body>
    </html>
  )
}